import { createMemoryHistory, createRouter } from "vue-router"
import { store } from "@/store"

const routes = [
  {
    name: "Home",
    path: "/",
    component: () => import("./components/Home.vue"),
  },
  {
    name: "Login",
    path: "/login",
    component: () => import("./components/Login.vue"),
  },
  {
    name: "Portfolio n°2",
    path: "/portfolio",
    component: () => import("./components/Portfolio.vue"),
  },
  {
    name: "Trading bot",
    path: "/trading-bot",
    component: () => import("./components/trading-bot.vue"),
  },
]

const router = createRouter({
  history: createMemoryHistory(),
  routes,
})

export default router
