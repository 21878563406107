<template>
  <div @mousemove="reset_activity" @keydown="reset_activity" @touchstart="reset_activity" @touchend="reset_activity"
    @scroll="reset_activity">
    <button class="menu" v-if="route.name !== 'Home' && route.name !== 'Login'" @click="go_to_home()">Go to
      Home</button>
    <div class="container mt-3">
      <div class="row">
        <div class="col-md-12">
          <h1 class="title">{{ route.meta.title || route.name }}</h1>
          <br>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, inject, provide, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import moment from 'moment'

const route = useRoute()
const router = useRouter()
const $cookies = inject('$cookies')

let interval = null

onMounted(() => {
  document.title = 'Goldan'

  reset_activity()

  window.addEventListener('mousemove', reset_activity)
  window.addEventListener('keydown', reset_activity)
  window.addEventListener('touchstart', reset_activity)
  window.addEventListener('touchend', reset_activity)
  window.addEventListener('scroll', reset_activity)

  if (/Safari/i.test(navigator.userAgent)) document.addEventListener('visibilitychange', go_to_home)

  interval = setInterval(() => {
    if (!is_logged_in() && route.name !== 'Login')
      router.push({ name: 'Login' })

    if (moment().diff(moment($cookies.get('last_activity_at')), 'seconds') >= moment.duration(5, 'minutes').asSeconds() && route.name !== 'Home')
      window.location.reload()
  }, 1000)
})

onBeforeUnmount(() => {
  window.removeEventListener('mousemove', reset_activity)
  window.removeEventListener('keydown', reset_activity)
  window.removeEventListener('touchstart', reset_activity)
  window.removeEventListener('touchend', reset_activity)
  window.removeEventListener('scroll', reset_activity)
  document.removeEventListener('visibilitychange', go_to_home)
  clearInterval(interval)
})

const reset_activity = () => $cookies.set('last_activity_at', moment().toISOString())

const go_to_home = () => window.location.reload()

const is_logged_in = () => $cookies.get('auth_token')

const logout = () => {
  $cookies.remove('auth_token')
  router.push('/login')
}



provide('is_logged_in', is_logged_in)
provide('logout', logout)
</script>

<style lang="sass">
*
  box-sizing: border-box
  margin: 0

html, body
  background-color: black !important
  color: white !important
  font-family: Arial, sans-serif
  width: 100%
  height: 100%

  #app
    height: 100%
    width: 100%
</style>

<style lang="sass" scoped>
.menu
  width: 100%
  background-color: purple
  border-color: purple
  color: white

  &:hover
    text-decoration: underline

.title
  text-align: center
  margin: 0
</style>
