import { createApp } from "vue"
import App from "./App.vue"
import VueCookies from "vue-cookies"
import "bootstrap/dist/css/bootstrap.min.css"
import VueSweetalert2 from "vue-sweetalert2"
import "sweetalert2/dist/sweetalert2.min.css"
import { library, dom } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import router from "./router"
import "bootstrap"

const app = createApp(App)

library.add(fas, far, fab)
dom.watch()

app.use(VueCookies)
app.use(VueSweetalert2)
app.use(router)

app.component("font-awesome-icon", FontAwesomeIcon)

app.mount("#app")
